import { QueryHookOptions, useQuery } from '@apollo/client';

import { useFilterConditions } from '@/contexts';
import { gql } from '@/generated';
import { FilterCond, SdgPageQuery, SdgPageQueryVariables } from '@/generated/graphql';
import { SelectOption } from 'ui-v2';
import { useSdgFilter } from './useSdgFilter';

const QUERY = gql(/* GraphQL */ `
  query SdgPage($filter: [FilterCond]) {
    forestryIndicators(filter: $filter) {
      ...Sdg_LandCertifiedFragment
      ...Sdg_LandRestoredFragment
      ...Sdg_Emissions1And2RemovedFragment
      ...Sdg_EmissionsRemovedFragment
      ...Sdg_DecentJobsFragment
      ...Sdg_InvesteeRevenueGrowthFragment
    }
  }
`);

export function useSdgData(
  sdgFilter: SelectOption<string> | null,
  options: QueryHookOptions<SdgPageQuery, SdgPageQueryVariables> = {}
) {
  const { viewer, benchmark } = useFilterConditions();
  const sdgCondition = useSdgFilter(sdgFilter);

  const mergeSdgFilter = (filters: FilterCond[] | null) => {
    const res = (filters || []).filter((x) => x.field === 'region');

    if (sdgCondition) {
      res.push(sdgCondition);
    }

    return res;
  };

  const benchmarkQuery = useQuery(QUERY, {
    variables: { filter: mergeSdgFilter(benchmark) },
    ...options,
  });

  const viewerQuery = useQuery(QUERY, {
    variables: { filter: mergeSdgFilter(viewer) },
    skip: !viewer,
  });

  return {
    loading: benchmarkQuery.loading || viewerQuery.loading,
    benchmarkQuery,
    viewerQuery,
    benchmarkData: benchmarkQuery.data?.forestryIndicators,
    viewerData: viewerQuery.data?.forestryIndicators,
  };
}
