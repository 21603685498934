import { faSdg8 } from '@awesome.me/kit-335a9e77a1/icons/kit/custom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartCard, InfoTooltip, LabelText, VerticalBar } from 'ui-v2';

import { type FragmentType, gql, useFragment } from '@/generated';
import { getReferenceLines } from '../-helpers/getReferenceLines';
import { getTarget } from '../-helpers/getSDGTargetData';
import { useChartData } from '../-hooks/useChartData';

export const Sdg_InvesteeRevenueGrowthFragment = gql(/* GraphQL */ `
  fragment Sdg_InvesteeRevenueGrowthFragment on ForestryIndicators {
    investeeRevenueGrowth {
      average
      median
      n
      isDataSuppressed
    }
  }
`);

type InvesteeRevenueGrowthProps = {
  className?: string;
  indicators: FragmentType<typeof Sdg_InvesteeRevenueGrowthFragment>;
  viewerIndicators?: FragmentType<typeof Sdg_InvesteeRevenueGrowthFragment>;
  region: string;
};

export function InvesteeRevenueGrowth({
  indicators,
  viewerIndicators,
  region,
  className,
}: InvesteeRevenueGrowthProps) {
  const { t } = useTranslation();

  const benchmark = useFragment(Sdg_InvesteeRevenueGrowthFragment, indicators);
  const viewer = useFragment(Sdg_InvesteeRevenueGrowthFragment, viewerIndicators);

  const benchmarkData = benchmark.investeeRevenueGrowth;
  const viewerData = viewer?.investeeRevenueGrowth;

  const sdgTarget = useMemo(() => getTarget('emissionsRemoved', region), [region]);
  const referenceLines = getReferenceLines(sdgTarget);

  const { groups, data, yAxisProps } = useChartData({
    viewerData,
    benchmarkData,
    referenceLineValues: referenceLines.map((x) => x.value),
  });

  const titleTooltip = t('investeeRevenueGrowth_titleTooltip_sdg');

  return (
    <ChartCard className={className}>
      <ChartCard.Header>
        <div className="flex items-center gap-1.5">
          <FontAwesomeIcon icon={faSdg8} size="2x" className="text-sdg-8" />
          <LabelText>{t('sdgsTargeted_optionLabel_8').replace('8', '8.1')}</LabelText>
        </div>
        <ChartCard.Title>
          {t('investeeRevenueGrowth_title')}
          {titleTooltip && <InfoTooltip className="ml-1">{titleTooltip}</InfoTooltip>}
        </ChartCard.Title>
      </ChartCard.Header>
      <ChartCard.Body>
        <VerticalBar groups={groups} data={data}>
          <VerticalBar.Chart yAxis={yAxisProps} referenceLines={referenceLines} />
          <VerticalBar.Legend className="flex items-center justify-center gap-4" />
        </VerticalBar>
      </ChartCard.Body>
    </ChartCard>
  );
}
