import { faAngleDown } from '@awesome.me/kit-335a9e77a1/icons/classic/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { cn } from 'utils';

export interface SelectOption<T> {
  value: T;
  label: string;
  type?: string;
  section?: string;
}

interface SelectProps<T> {
  options: SelectOption<T>[];
  value: SelectOption<T> | null;
  onChange: (value: SelectOption<T>) => void;
  title?: string;
  className?: string;
  searchable?: boolean;
  placeholder?: string;
}

export const Select = <T,>({
  options,
  value,
  onChange,
  title,
  className,
  searchable = false,
  placeholder = 'Select...',
}: SelectProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  const filteredOptions = useMemo(() => {
    if (!searchable) return options;
    return options.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [options, searchTerm, searchable]);

  const sections = useMemo(() => {
    const sectionMap: { [key: string]: SelectOption<T>[] } = {};
    filteredOptions.forEach((option) => {
      const section = option.section || 'Default';
      if (!sectionMap[section]) sectionMap[section] = [];
      sectionMap[section].push(option);
    });
    return sectionMap;
  }, [filteredOptions]);

  const handleSelect = (selectedOption: SelectOption<T>) => {
    onChange(selectedOption);
    setIsOpen(false);
    setSearchTerm('');
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={cn('relative w-full md:w-[300px]', className)}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="focus:ring-barbie flex w-full items-center justify-between rounded-lg border border-gray-300 px-4 py-1.5 text-left focus:outline-none focus:ring-2"
      >
        <div className="flex flex-col">
          {title && (
            <span className="text-barbie text-[0.625rem] uppercase leading-[0.875rem]">
              {title}
            </span>
          )}
          <span className="text-[0.75rem] font-normal leading-4 text-gray-900">
            {value?.label || placeholder}
          </span>
        </div>
        <FontAwesomeIcon
          icon={faAngleDown}
          className={cn('text-barbie transform transition-transform duration-200', {
            'rotate-180': isOpen,
          })}
        />
      </button>

      {isOpen && (
        <div className="absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-lg border border-gray-300 bg-white shadow-lg lg:max-h-80">
          {searchable && (
            <div className="p-2">
              <input
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full rounded-md border border-gray-300 px-2 py-1"
              />
            </div>
          )}
          <ul>
            {Object.entries(sections).map(([section, sectionOptions]) => (
              <li key={section} className="border-b border-gray-300 text-sm">
                {section !== 'Default' && (
                  <p className="px-4 py-2 font-semibold text-gray-500">{section}</p>
                )}
                {sectionOptions.map((option) => (
                  <p
                    key={String(option.value)}
                    onClick={() => handleSelect(option)}
                    className={cn('cursor-pointer px-4 py-2 text-gray-900 hover:bg-gray-200', {
                      'text-barbie font-bold': option.value === value?.value,
                    })}
                  >
                    {option.label}
                  </p>
                ))}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
