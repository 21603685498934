import { faSdg15 } from '@awesome.me/kit-335a9e77a1/icons/kit/custom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartCard, InfoTooltip, LabelText, VerticalBar } from 'ui-v2';

import { type FragmentType, gql, useFragment } from '@/generated';
import { getReferenceLines } from '../-helpers/getReferenceLines';
import { getTarget } from '../-helpers/getSDGTargetData';
import { useChartData } from '../-hooks/useChartData';

export const Sdg_LandCertifiedFragment = gql(/* GraphQL */ `
  fragment Sdg_LandCertifiedFragment on ForestryIndicators {
    pctLandCertified {
      average
      median
      n
      isDataSuppressed
    }
  }
`);

type LandCertifiedProps = {
  className?: string;
  indicators: FragmentType<typeof Sdg_LandCertifiedFragment>;
  viewerIndicators?: FragmentType<typeof Sdg_LandCertifiedFragment>;
  region: string;
};

export function LandCertified({
  indicators,
  viewerIndicators,
  region,
  className,
}: LandCertifiedProps) {
  const { t } = useTranslation();

  const benchmark = useFragment(Sdg_LandCertifiedFragment, indicators);
  const viewer = useFragment(Sdg_LandCertifiedFragment, viewerIndicators);

  const benchmarkData = benchmark.pctLandCertified;
  const viewerData = viewer?.pctLandCertified;

  const sdgTarget = useMemo(() => getTarget('landManaged', region), [region]);
  const referenceLines = getReferenceLines(sdgTarget);

  const { groups, data, yAxisProps } = useChartData({
    viewerData,
    benchmarkData,
    referenceLineValues: referenceLines.map((x) => x.value),
  });

  const titleTooltip = t('landManaged_titleTooltip_sdg');

  return (
    <ChartCard className={className}>
      <ChartCard.Header>
        <div className="flex items-center gap-1.5">
          <FontAwesomeIcon icon={faSdg15} size="2x" className="text-sdg-15" />
          <LabelText>{t('sdgsTargeted_optionLabel_15')}</LabelText>
        </div>
        <ChartCard.Title>
          {t('landManaged_title')}
          {titleTooltip && <InfoTooltip className="ml-1">{titleTooltip}</InfoTooltip>}
        </ChartCard.Title>
      </ChartCard.Header>
      <ChartCard.Body>
        <VerticalBar groups={groups} data={data}>
          <VerticalBar.Chart yAxis={yAxisProps} referenceLines={referenceLines} />
          <VerticalBar.Legend className="flex items-center justify-center gap-4" />
        </VerticalBar>
      </ChartCard.Body>
    </ChartCard>
  );
}
