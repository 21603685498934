import { FilterCond, FilterField, FilterOp } from '@/generated/graphql';
import { SelectOption } from 'ui-v2';

export function useSdgFilter(sdgFilter: SelectOption<string> | null): FilterCond | null {
  if (!sdgFilter || sdgFilter.value === 'World') return null;

  if (sdgFilter.section === 'Region') {
    return {
      field: FilterField.Region,
      op: FilterOp.Eq,
      value: sdgFilter.value,
    };
  }

  if (sdgFilter.section === 'Country') {
    return {
      field: FilterField.PrimaryCountry,
      op: FilterOp.Eq,
      value: sdgFilter.value,
    };
  }

  return null;
}
