import { IReferenceLine } from 'ui-v2/components/VerticalBar/VerticalBarChart';
import { colors } from 'ui-v2/theme';

export const getReferenceLines = (sdgTarget: number | null) => {
  const referenceLines: IReferenceLine[] = [{ value: 0, color: colors.gray[500] }];

  if (sdgTarget !== null) {
    referenceLines.push({
      value: parseFloat((sdgTarget * 100).toFixed(2)),
      color: colors.barbie,
      label: `TARGET (${(sdgTarget * 100).toFixed(2)}%)`,
    });
  }

  return referenceLines;
};
