import { capitalize, groupBy, uniqBy } from 'lodash-es';

import rawData from '../-data/targets.csv';

const KPI_COLUMN_MAP = {
  landManaged: 'KPI 1: Amount of land managed',
  landProtected: 'KPI 2: Amount of land protected',
  landRestored: 'KPI 3: Percentage of new and/or restored forest area',
  emissions1And2Removed: 'KPI 4: Emissions scope 1 and 2',
  emissionsRemoved: 'KPI 5: Emissions removed (including sequestered)',
  decentJobs: 'KPI 7: Decent jobs',
  investeeRevenueGrowth: 'KPI 8: Investee revenue growth',
};

interface SDGTargetRow {
  KPI: string;
  Type: 'Country' | 'Region' | 'World';
  Geography: string;
  'ISO-3': string;
  Target: number;
}

export function getSDGTargetData() {
  return rawData as SDGTargetRow[];
}

export function getRegionsByType(type: 'country' | 'region' | 'world') {
  return uniqBy(getSDGTargetData(), 'Geography')
    .filter((x) => x.Type === capitalize(type))
    .map((x) => x.Geography)
    .sort();
}

export function getRegionType(region: string): SDGTargetRow['Type'] {
  const match = getSDGTargetData().find((x) => x.Geography === region);
  if (!match) throw new Error(`Unknown Region: ${region}`);
  return match.Type;
}

export function getTarget(kpi: keyof typeof KPI_COLUMN_MAP, region: string): number | null {
  const targets = groupBy(getSDGTargetData(), 'KPI')[KPI_COLUMN_MAP[kpi]] ?? [];
  return targets.find((t) => t.Geography === region)?.Target ?? null;
}
